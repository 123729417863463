import {
	InputWithConfirmation,
	useInputWithConfirmation,
} from "@/modules/InputWithConfirmation";
import {
	useOrganizationQuery,
	useUpdateOrganization,
} from "@/modules/Organizations/logic";
import { useRouter } from "@/modules/Router";
import { Upload, useUpload } from "@/modules/Upload";
import { useOpenable } from "@/modules/useOpenable";
import {
	useInviteUser,
	User,
	useUpdateUserRoles,
	useUsersQuery,
} from "@/modules/UserManagement/logic";
import { UserRole, userRoles } from "@/modules/Viewer/components/Viewer";
import { createClass } from "@/reexports";
import { notifySuccess } from "@/services/notification";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import { Icon } from "@/views";
import { Multiselect } from "@/views/Multiselect";
import { Searchbar } from "@/views/Searchbar";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { createStore } from "reusable";
import { WorkspaceLogo } from "./Logo";

enum SORT_OPTIONS {
	CREATED_AT = "CREATED_AT",
	UPDATED_AT = "UPDATED_AT",
	EMAIL = "EMAIL",
}

const useWorkspaceDetailFilters = () => {
	const [search, setSearch] = useState<string>("");
	const [createdAfter, setCreatedAfter] = useState<string>();
	const [createdBefore, setCreatedBefore] = useState<string>();
	const [updatedAfter, setUpdatedAfter] = useState<string>();
	const [updatedBefore, setUpdatedBefore] = useState<string>();

	const [sortBy, setSortBy] = useState<SORT_OPTIONS>(SORT_OPTIONS.EMAIL);

	return {
		search,
		setSearch,
		createdAfter,
		setCreatedAfter,
		createdBefore,
		setCreatedBefore,
		updatedAfter,
		setUpdatedAfter,
		updatedBefore,
		setUpdatedBefore,
		sortBy,
		setSortBy,
	};
};

export const useWorkspaceActiveDetail = createStore(() => {
	const router = useRouter();
	const id = useMemo(() => {
		return router.route.route === "workspaces-detail"
			? router.route.params.id
			: "";
	}, [router.route]);

	return {
		id,
	};
});

export const WorkspaceDetail = ({ id }: { id: string }) => {
	return (
		<div className="flex flex-col mt-6 gap-6 mx-20">
			<Settings id={id} />
			<UserManagement />
		</div>
	);
};

const Settings = ({ id }: { id: string }) => {
	const organization = useOrganizationQuery(id);
	const name = useMemo(() => organization.data?.organization?.name || "", [
		organization,
	]);
	const [updateOrganization] = useUpdateOrganization();
	const modal = useOpenable();
	const upload = useUpload({
		onUploaded: (logo, isLatest) => {
			if (isLatest) {
				updateOrganization({
					variables: {
						organizationId: id,
						logo,
					},
				});
			}
		},
	});
	const updateName = useCallback(
		(name: string) => {
			return updateOrganization({
				variables: {
					organizationId: id,
					name,
				},
			});
		},
		[id, updateOrganization]
	);

	const disabled = useMemo(() => {
		return !organization.data?.organization;
	}, [organization]);

	const nameInput = useInputWithConfirmation({
		onSave: updateName,
		value: name,
	});

	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<>
			<div className="center-v flex-col gap-6 py-8 px-9 rounded-lg shadow-flat-down bg-white">
				<div className="center gap-2 relative">
					<WorkspaceLogo id={id} size="gigantic" />
					<Icon
						name="edit"
						className="absolute right-0 transform translate-x-6 hover:text-blue-300"
						weight="solid"
						onClick={modal.open}
					/>
				</div>
				<div className="center gap-2 relative">
					<label htmlFor="name">Name:</label>
					<InputWithConfirmation {...nameInput}>
						<div className="center-v relative">
							<input
								ref={inputRef}
								className={createClass("input w-full", {
									"bg-gray-200":
										!nameInput.editing || disabled,
								})}
								id="name"
								type="text"
								value={nameInput.value}
								onClick={nameInput.edit}
								onChange={(e) =>
									nameInput.onChange(e.target.value)
								}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										return nameInput.save();
									}
									if (e.key === "Escape") {
										if (inputRef.current) {
											inputRef.current.blur();
										}
										return nameInput.dismiss();
									}
								}}
							/>
							<Icon
								name="edit"
								size="mili"
								className="absolute right-0 transform -translate-x-2 hover:text-blue-300"
								weight="solid"
								onClick={() => {
									if (inputRef.current) {
										inputRef.current.focus();
									}
									nameInput.edit();
								}}
							/>
						</div>
					</InputWithConfirmation>
				</div>
			</div>
			<Upload onClose={modal.close} opened={modal.opened} {...upload} />
		</>
	);
};

const UserManagement = () => {
	return (
		<div className="flex flex-col gap-6 py-8 px-9 rounded-lg shadow-flat-down bg-white">
			<div className="center">
				<span className="text-2xl font-bold">Members {"&"} Rights</span>
			</div>
			<UsersControls />
			<UsersTable />
		</div>
	);
};

const UsersControls = () => {
	return (
		<div className="flex gap-4">
			<UsersSearchbar />
			<UserInvite />
		</div>
	);
};
const UsersSearchbar = () => {
	const { search, setSearch } = useWorkspaceDetailFilters();

	return (
		<Searchbar
			className="w-80 bg-white"
			onChange={(e) => setSearch(e.target.value)}
			value={search}
			placeholder="Search...."
		/>
	);
};

const UserInvite = () => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });
	const [state, setState] = useState("");
	const [invite, inviteState] = useInviteUser();

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (!state) return;
				invite({
					variables: { email: state, organizationId: id },
					update: () => {
						notifySuccess(`${state} was added to the users`);
						setState("");
						users.refetch();
					},
				});
			}}
			className="w-80 relative center-v gap-2 input"
		>
			<input
				className="w-10/12 bg-transparent outline-none"
				onChange={(e) => setState(e.target.value)}
				value={state}
				pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
				type="email"
				placeholder="email"
			/>
			{inviteState.loading ? (
				<LoadingIcon />
			) : (
				<button
					type="submit"
					className="right-0 transform -translate-x-1 absolute text-sm btn-primary"
				>
					Invite
				</button>
			)}
		</form>
	);
};

const UsersTable = () => {
	return (
		<div className="flex flex-col gap-4">
			<HeaderRow />
			<UserRows />
		</div>
	);
};

const HeaderRow = () => {
	return (
		<div className="center-v mb-4">
			<span className="w-2/12 text-center">Name</span>
			<span className="w-3/12 text-center">E-mail</span>
			<span className="w-3/12 text-center">Roles</span>
			<span className="w-2/12 text-center">Created At</span>
			<span className="w-2/12 text-center">Updated At</span>
		</div>
	);
};

const UserRows = () => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });

	if (users.loading)
		return (
			<LoadingIcon className="h-144 center text-blue-300" size="huge" />
		);

	return (
		<div className="flex flex-col gap-4 h-144 overflow-overlay">
			{users.data?.users.map((user) => (
				<UserRow
					key={user.id}
					user={{
						...user,
						organizationMemberships:
							user.organizationMemberships || [],
					}}
				/>
			))}
		</div>
	);
};

const UserRow = ({ user }: { user: User }) => {
	const { id } = useWorkspaceActiveDetail();
	const users = useUsersQuery({ organizationId: id });
	const [update] = useUpdateUserRoles();
	const getRoles = useCallback(
		() => user.organizationMemberships.map((m) => m.role),
		[user]
	);

	const save = useCallback(
		(roles: UserRole[]) =>
			update({
				variables: {
					organizationId: id,
					roles,
					userId: user.id,
				},
				update: () => {
					users.refetch();
				},
			}),
		[id, update, user.id, users]
	);

	const rolesInput = useInputWithConfirmation({
		onSave: save,
		value: getRoles(),
	});

	return (
		<div className="center-v text-xs">
			<span className="ellipsis w-2/12 text-center ">{user.email}</span>
			<span className="w-3/12 text-center">{user.email}</span>
			<span className="w-3/12 center relative">
				<InputWithConfirmation className="w-full" {...rolesInput}>
					<Multiselect
						value={rolesInput.value}
						select={rolesInput.onChange}
						options={userRoles.map((role) => ({
							label: role,
							value: role,
						}))}
					/>
				</InputWithConfirmation>
			</span>
			<span className="w-2/12 text-center">TBA</span>
			<span className="w-2/12 text-center">TBA</span>
		</div>
	);
};
