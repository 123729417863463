import { Link, RouteType } from "@/modules/Router";
import { createClass } from "@/reexports";
import * as React from "react";

type NavigationLinkProps = {
	to: RouteType;
	active: boolean;
	children: JSX.Element | JSX.Element[];
};

export const NavigationLink = ({
	to,
	active,
	children,
}: NavigationLinkProps) => {
	return (
		<Link to={to}>
			<div
				className={createClass("cursor-pointer mr-6 last:mr-0", {
					"text-c-accent1": active,
				})}
			>
				{children}
			</div>
		</Link>
	);
};
