import {
	IntegrationFeedInstanceSelector,
	useAbortEtl,
	useRunEtl,
} from "@/modules/Etl/IntegrationFeedInstances/logic";
import {
	FeedIcon,
	feedTypeToBrand,
} from "@/modules/Etl/IntegrationFeedParams/ui/FeedIcon";
import { Link } from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { useTypedFetchQuery } from "@/services/graphApi";
import {
	InstanceState,
	IntegrationFeedInstance,
} from "@/services/graphApi/graphql-zeus";
import { Dropdown, Icon, Tile } from "@/views";
import dayjs from "dayjs";
import React from "react";

type Props = {
	item: IntegrationFeedInstance;
	refetch: () => void;
};

const SIZE = "w-96 h-48";
export const InstanceTile = ({ item, refetch }: Props) => {
	const aborted = useOpenable(false);
	const menu = useOpenable(false);
	const [runEtl] = useRunEtl();
	const [abortEtl, abortEtlState] = useAbortEtl();
	const fetch = useTypedFetchQuery({
		integrationFeedInstance: [
			{ where: { id: item.id } },
			IntegrationFeedInstanceSelector(item.params.feedType),
		],
	});

	// const errorAccordion = useOpenable(false);

	const stateClasses = createClass({
		"opacity-0": aborted.opened,
		"border-fail": item.state === "FAILED",
		"border-warn": item.state === "RETRYING",
		"border-success": item.state === "SUCCEEDED",
		"border-gray-500": item.state === "ABORTED",
		"border-blue-500": item.state === "RUNNING",
	});

	if (abortEtlState.loading) {
		const deleteClasses = createClass(
			SIZE,
			"center bg-white flex text-gray-800 border-l-12 transition-03",
			stateClasses
		);
		return (
			<Tile className={deleteClasses}>
				<Icon name="spinner" className="spin" size="big" />
			</Tile>
		);
	}

	// const accordionClasses = {
	// 	text: createClass("pr-4 text-xs", { ellipsis: !errorAccordion.opened }),
	// };

	const classes = createClass(
		SIZE,
		"bg-white flex text-gray-800 border-l-12 transition-03",
		// { "w-144 h-56": errorAccordion.opened },
		stateClasses
	);

	return (
		<Tile key={item.id} className={classes}>
			<>
				<div className="w-full py-4 px-3">
					<div className="flex h-full justify-between mb-2">
						<div className="mt-1 mr-4">
							<FeedIcon
								type={feedTypeToBrand(item.params.feedType)}
							/>
						</div>
						<div className="flex flex-col justify-between">
							<div className="font-bold text-2xl truncate-3-lines">
								{item.params.descriptiveName}
							</div>
							<div className="flex flex-col gap-1 mb-2">
								<div className="center-v gap-1 text-xs">
									<div className="w-16 text-c-accent1">
										Updated at:
									</div>
									<div className="">
										{dayjs(item.updatedAt).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</div>
								</div>
								<div className="center-v pr-1 gap-1 text-xs">
									<div className="w-16 text-c-accent1">
										Created at:
									</div>
									<div>
										{dayjs(item.createdAt).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</div>
								</div>
								{/* <div className="underline font-semibold text-teal-400">
									Destination link
								</div> */}
							</div>
						</div>
						<div className="flex ai-start">
							<div className="center-v">
								<Link
									to={{
										route: "etl-instances-detail",
										params: {
											id: item.id,
											feedType: item.params.feedType,
										},
									}}
								>
									<Icon
										className="cursor-pointer py-2 px-2 rounded transition duration-300 hover:bg-gray-200"
										name="search"
										size="big"
										weight="solid"
									/>
								</Link>
								<Dropdown.View
									className="hover:text-blue-300 transition duration-100"
									onClickOutside={menu.close}
									opened={menu.opened}
									label={
										<Icon
											onClick={(e) => {
												e.stopPropagation();
												menu.toggle();
											}}
											name="ellipsis-h"
											weight="solid"
											size="big"
										/>
									}
								>
									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() => {
												fetch().then((res) => {
													const transformedItem = JSON.parse(
														JSON.stringify(
															res.data
																?.integrationFeedInstance,
															queriedInstanceToRunnableInstance
														)
													);
													const {
														config,
														...data
													} = transformedItem;

													runEtl({
														variables: {
															data: {
																...data,
																...(config
																	? {
																			config: {
																				id:
																					config.id,
																			},
																	  }
																	: {}),
															},
														},
													});
												});
												menu.close();
											}}
										>
											<div>Run instance</div>
										</Dropdown.Item>
										{(item.state ===
											InstanceState.RUNNING ||
											item.state ===
												InstanceState.RETRYING) && (
											<Dropdown.Item
												onClick={() => {
													menu.close();
													abortEtl({
														variables: {
															id:
																item.stateMachineExecutionArn,
														},
														update: () => {
															aborted.open();
															refetch();
														},
													});
												}}
											>
												<div>Abort instance</div>
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown.View>
							</div>
						</div>
					</div>
					{/* <div className="center-v justify-around mb-3">
					<div className="cursor-pointer py-2 px-4 rounded transition duration-300 hover:bg-gray-200 flex flex-col ai-center">
						<Icon
							className="cursor-pointer mb-2"
							name="file"
							size="big"
							weight="solid"
						/>
						<div className="text-xs">Text</div>
					</div>
					<div className="cursor-pointer py-2 px-4 rounded transition duration-300 hover:bg-gray-200 flex flex-col ai-center">
						<Icon
							className="cursor-pointer mb-2"
							name="file"
							size="big"
							weight="solid"
						/>
						<div className="text-xs">Text</div>
					</div>
					<div className="cursor-pointer py-2 px-4 rounded transition duration-300 hover:bg-gray-200 flex flex-col ai-center">
						<Icon
							className="cursor-pointer mb-2"
							name="file"
							size="big"
							weight="solid"
						/>
						<div className="text-xs">Text</div>
					</div>
					<div className="cursor-pointer py-2 px-4 rounded transition duration-300 hover:bg-gray-200 flex flex-col ai-center">
						<Icon
							className="cursor-pointer mb-2"
							name="file"
							size="big"
							weight="solid"
						/>
						<div className="text-xs">Text</div>
					</div>
					<div className="cursor-pointer py-2 px-4 rounded transition duration-300 hover:bg-gray-200 flex flex-col ai-center">
						<Icon
							className="cursor-pointer mb-2"
							name="file"
							size="big"
							weight="solid"
						/>
						<div className="text-xs">Text</div>
					</div>
				</div>
				<div className="rounded center-v justify-between w-full bg-gray-200 px-2 py-half">
					<div className="ellipsis pr-4 text-xs">
						Lorem, ipsum dolor sit amet consectetur adipisicing
						elit. Ipsum cupiditate voluptates adipisci, a provident
						quod ad velit, atque maiores laboriosam explicabo
						ratione voluptatem fugiat optio.
					</div>
					<Icon name="chevron-down" size="nano" />
				</div> */}
					{/* {item.failureError?.message && (
						<div className="rounded w-full bg-gray-200 px-2 py-half">
							<div className={accordionClasses.text}>
								{item.failureError?.message}
							</div>
							<div
								className="cursor-pointer hover:text-blue-300"
								onClick={errorAccordion.toggle}
							>
								show {errorAccordion.opened ? "less" : "more"}
							</div>
						</div>
					)} */}
				</div>
			</>
		</Tile>
	);
};

const queriedInstanceToRunnableInstance = (key: string, value: any) => {
	if (key === "") {
		const { config, organizationAttributes, params } = value;
		return {
			config,
			organizationAttributes,
			params,
		};
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	if (key === "bqDestinations" || key === "s3Destinations") {
		return value
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
	}
	return value;
};
