import { unit } from "@/functions/unit";
import { Metrics } from "@/modules";
import { useMetricsStore } from "@/model";
import { useOpenable } from "@/modules/useOpenable";
import { useSelect } from "@/modules/useSelect";
import { createClass } from "@/reexports";
import * as Views from "@/views";
import { DnDList } from "@/views/DnDList";
import * as React from "react";
import { DraggableProvided, DropResult } from "react-beautiful-dnd";
import { createStore } from "reusable";
import { useForm, FormContext } from "react-hook-form";

type CheckedCumulativeMetric = {
    value: Metrics.Cumulative;
    label: string;
    selected: boolean;
    checked: boolean;
};

type AddCheckedMetricButtonStatus = "partial" | "inverted" | "default";

const getLifetimeMetrics = (metrics: Metrics.Model) =>
    Metrics.lifetime.map(metric =>
        metrics
            .filter(metric => !metric.day_of_activity)
            .some(Metrics.equals(metric))
            ? { ...metric, selected: true }
            : { ...metric, selected: false }
    );

export const useMetrickPicker = createStore(() => {
    const [searchInput, setSearchInput] = React.useState("");
    return { ...useOpenable(), searchInput, setSearchInput };
});

export const View = () => {
    const searchbarRef = React.useRef<HTMLInputElement>(null);
    const { opened, close } = useMetrickPicker();

    React.useEffect(() => {
        if (searchbarRef.current) searchbarRef.current.focus();
    });

    return (
        <Views.Modal isOpened={opened} onClose={close}>
            <div>
                <div className="relative flex flex-col w-metric-picker-width h-metric-picker-height p-7 text-sm">
                    <Top searchbarRef={searchbarRef} />
                    <div className="flex justify-between items-start h-metric-picker-selected-height">
                        <div className="flex-basis-55 overflow-overlay">
                            <LeftLifetime />
                            <LeftCumulative />
                        </div>
                        <Right />
                    </div>
                    <div className="absolute bottom-0 mb-7 w-full-14">
                        <Bottom />
                    </div>
                </div>
            </div>
        </Views.Modal>
    );
};

type TopProps = {
    searchbarRef: React.RefObject<HTMLInputElement>;
};

const Top = ({ searchbarRef }: TopProps) => {
    const { close, searchInput, setSearchInput } = useMetrickPicker();

    const methods = useForm({ defaultValues: { search: searchInput } });

    return (
        <div className="mb-4">
            <div className="center-v justify-between">
                <div className="center-v">
                    <Views.Icon
                        className="mr-4"
                        name="plus-square"
                        size="large"
                    />
                    <span className="text-huge font-semibold">Metrics</span>
                </div>
                <div className="relative center-v">
                    <FormContext { ...methods } >
                        <Views.ViewInput
                            placeholder="filter metrics"
                            value={searchInput}
                            onChange={e => {
                                setSearchInput(e.toString());
                                return e;
                            }}
                            id="search"
                        />
                        <Views.Icon
                            className="absolute right-0 -translate-x-2 transform"
                            name="search"
                        />
                    </FormContext>
                </div>
                <Views.Icon
                    name="times"
                    weight="solid"
                    size="large"
                    onClick={close}
                />
            </div>
        </div>
    );
};

const LeftLifetime = () => {
    const [metrics, dispatch] = useMetricsStore();
    const { searchInput } = useMetrickPicker();

    const unselect = (metrics: Metrics.MetricInstance[]) =>
        dispatch({
            type: "UNSELECT",
            payload: metrics
        });

    const select = (metrics: Metrics.MetricInstance[]) =>
        dispatch({
            type: "SELECT",
            payload: metrics
        });

    return (
        <div className="flex-basis-55 overflow-overlay">
            <div className="flex flex-col">
                <div className="mb-micro">
                    <div className="flex flex-col">
                        <div className="border-b-2 border-main-font mb-1 font-semibold text-large h-8 flex-shrink-0">
                            <Views.Icon
                                className="mr-2"
                                size="big"
                                weight="solid"
                                name="arrows-h"
                            />
                            <span>Lifetime metrics</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    {getLifetimeMetrics(metrics)
                        .filter(item =>
                            item.label
                                .toLowerCase()
                                .includes(searchInput.toLowerCase())
                        )
                        .map(metric => {
                            return (
                                <ViewLifetimeMetricButton
                                    key={metric.value}
                                    className="mr-2 mb-2"
                                    onClick={() => {
                                        metric.selected
                                            ? unselect([
                                                  Metrics.createMetric(
                                                      metric.value
                                                  )
                                              ])
                                            : select([
                                                  Metrics.createMetric(
                                                      metric.value
                                                  )
                                              ]);
                                    }}
                                    inverted={!metric.selected}
                                >
                                    {metric.label}
                                </ViewLifetimeMetricButton>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

type CumulativeMetric = {
    value: Metrics.Cumulative;
    label: string;
    selected: boolean;
    checked: boolean;
};
const getCumulativeMetrics = (
    oldCumulativeMetrics: CumulativeMetric[],
    metrics: Metrics.Model
): CumulativeMetric[] =>
    Metrics.cumulative.map((metric, index) => ({
        value: metric.value,
        label: metric.label,
        selected: metrics
            .filter(Metrics.isInstanceCumulative)
            .some(val => val.value === metric.value),
        checked:
            oldCumulativeMetrics[index] &&
            !!oldCumulativeMetrics[index]["checked"]
    }));

const useCumulative = createStore(() => {
    const [metrics] = useMetricsStore();

    const [cumulative, setCumulative] = React.useState(
        getCumulativeMetrics([], metrics)
    );

    const checked = cumulative.filter(item => item.checked);

    React.useEffect(() => {
        setCumulative(prev => getCumulativeMetrics(prev, metrics));
    }, [metrics, setCumulative]);

    const uncheckAll = () =>
        setCumulative(cumulative.map(item => ({ ...item, checked: false })));

    const toggleCheck = (cumulativeMetricValue: Metrics.Cumulative) =>
        setCumulative(
            cumulative.map(metric => {
                if (metric.value === cumulativeMetricValue) {
                    return {
                        ...metric,
                        checked: !metric.checked
                    };
                }
                return metric;
            })
        );

    return { cumulative, checked, uncheckAll, toggleCheck };
});

const LeftCumulative = () => {
    const { searchInput } = useMetrickPicker();
    const { cumulative, uncheckAll } = useCumulative();

    return (
        <div className="flex flex-col">
            <div className="mb-micro">
                <div className="flex flex-col">
                    <div className="center-v justify-between border-b-2 border-main-font mb-1 font-semibold text-large h-8 flex-shrink-0">
                        <div className="center-v">
                            <Views.Icon
                                className="mr-2"
                                size="big"
                                weight="solid"
                                name="arrow-to-right"
                            />
                            <span>Cumulative metrics</span>
                        </div>
                        <div
                            className="cursor-pointer pr-2 text-sm text-purple"
                            onClick={uncheckAll}
                        >
                            uncheck all
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                {cumulative
                    .filter(item =>
                        item.label
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                    )
                    .map(metric => {
                        return (
                            <CumulativeMetricButton
                                key={metric.value}
                                metric={metric}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

const Right = () => {
    const [metrics, dispatch] = useMetricsStore();

    const unselect = (metrics: Metrics.MetricInstance[]) =>
        dispatch({
            type: "UNSELECT",
            payload: metrics
        });

    const changeOrderOfSelectedMetrics = (event: DropResult) =>
        dispatch({
            type: "CHANGE_ORDER_OF_SELECTED",
            payload: event
        });

    return (
        <div className="h-full flex-basis-35">
            <div className="h-full flex flex-col">
                <div className="mb-micro mr-8">
                    <div className="flex flex-col">
                        <div className="border-b-2 border-main-font mb-1 font-semibold text-large h-8 flex-shrink-0">
                            <span className="mr-2">{metrics.length}</span>
                            <span>selected metrics</span>
                        </div>
                    </div>
                </div>
                <DnDList
                    className="h-full pr-8 overflow-overlay"
                    onDragEnd={changeOrderOfSelectedMetrics}
                >
                    {metrics.map((metric, index) => provided => {
                        return (
                            <SelectedMetricButton
                                key={index}
                                metric={metric}
                                provided={provided}
                                onRemove={() => unselect([metric])}
                            />
                        );
                    })}
                </DnDList>
            </div>
        </div>
    );
};

const useAddMetricsDropdown = createStore(() => {
    const addOptions = {
        recommended: [1, 2, 3, 4, 5, 6, 7, 14, 28, 90],
        early: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    };

    const [metrics] = useMetricsStore();
    const _options = [
        {
            value: "recommended",
            label: "Recommended",
            options: [1, 2, 3, 4, 5, 6, 7, 14, 28, 90]
        },
        {
            value: "early",
            label: "Early",
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        },
        {
            value: "custom",
            label: "Custom",
            options: metrics
                .filter(metric => !!metric.day_of_activity)
                .map(metric => metric.day_of_activity as number)
                .filter(val => addOptions.early.includes(val))
                .filter(val => addOptions.recommended.includes(val))
        }
    ];

    return useSelect(_options, "recommended");
});

const Bottom = () => {
    const [metrics] = useMetricsStore();

    const [input, setInput] = React.useState(0);

    const dropdown = useAddMetricsDropdown();

    const isMetricSelected = (value: number) => (
        metric: CheckedCumulativeMetric
    ) =>
        metrics.some(
            selected =>
                metric.value === selected.value &&
                selected.day_of_activity === value
        );

    const cumulativeMetrics = useCumulative();

    const checkedCumulativeMetrics: CheckedCumulativeMetric[] = cumulativeMetrics.cumulative.filter(
        metric => metric.checked
    );
    const numbers = dropdown.selected?.options as number[];
    const numbersConfig = numbers.map(value => {
        const isInverted = !checkedCumulativeMetrics.every(
            isMetricSelected(value)
        );
        const isPartial =
            isInverted &&
            checkedCumulativeMetrics.some(isMetricSelected(value));
        return {
            value,
            status: isPartial
                ? "partial"
                : isInverted
                ? "inverted"
                : ("default" as AddCheckedMetricButtonStatus)
        };
    });

    const asterisk = {
        value: "*" as "*",
        status: numbersConfig.every(config => config.status === "partial")
            ? "partial"
            : numbersConfig.every(config => config.status === "inverted")
            ? "inverted"
            : ("default" as AddCheckedMetricButtonStatus)
    };

    const currentOptions = [asterisk, ...numbersConfig];

    return (
        <div className="flex flex-col">
            <div className="mb-4 border-b-2 border-main-font font-semibold text-large h-8 flex-shrink-0">
                <Views.Icon className="mr-2" size="large" name="check-square" />
                <span>Add cumulative metrics</span>
            </div>
            <div className="center-v">
                <div className="mr-8">
                    <Views.Dropdown.View
                        label={
                            <div
                                className="cursor-pointer center-v justify-between"
                                onClick={dropdown.open}
                            >
                                {dropdown.selectedValue}
                                <Views.Icon
                                    className="ml-2"
                                    name="chevron-down"
                                    size="nano"
                                />
                            </div>
                        }
                        opened={dropdown.opened}
                        onClickOutside={dropdown.close}
                    >
                        <Views.Dropdown.Menu>
                            {dropdown.options.map(option => (
                                <Views.Dropdown.Item
                                    key={option.value}
                                    selected={
                                        dropdown.selected?.value ===
                                        option.value
                                    }
                                    onClick={() => {
                                        dropdown.close();
                                        dropdown.select(option.value);
                                    }}
                                >
                                    {option.label}
                                </Views.Dropdown.Item>
                            ))}
                        </Views.Dropdown.Menu>
                    </Views.Dropdown.View>
                </div>
                <div className="flex">
                    {currentOptions.map(item => {
                        return (
                            <AddCumulativeMetricsButton
                                key={item.value}
                                option={item}
                            />
                        );
                    })}
                    {dropdown.selected?.value === "custom" ? (
                        <>
                            <Views.ViewInput
                                className="ml-2 mr-2"
                                value={input}
                                type="number"
                                onChange={e => {
                                    setInput(e as number);
                                    return e;
                                }}
                            />
                            <button
                                className="center rounded py-2 px-4 bg-c-accent2-pale text-white h-8 hover:bg-c-accent2-pale-darkened"
                                onClick={() => {
                                    dropdown.setOptions(
                                        dropdown.options.map((option, index) =>
                                            index === 2
                                                ? {
                                                      ...option,
                                                      options: option.options.concat(
                                                          input
                                                      )
                                                  }
                                                : option
                                        )
                                    );
                                    setInput(0);
                                }}
                            >
                                <div className="mr-2">
                                    <Views.Icon name="plus" />
                                </div>
                                <div>Add day</div>
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const getAddCumulativeMetricsButtonClasses = (
    status: AddCheckedMetricButtonStatus
) =>
    createClass(
        "center cursor-pointer mr-2 last:mr-0 w-8 h-8 rounded font-bold border border-c-accent2-pale",
        {
            "bg-c-accent2-pale-transparent hover:bg-c-accent2-pale-darkened text-c-accent2-pale hover:text-c-accent2-pale":
                status === "partial",
            "bg-white hover:bg-c-accent2-pale-lightened text-c-accent2-pale hover:text-c-accent2-pale":
                status === "inverted",
            "bg-c-accent2-pale hover:bg-c-accent2-pale-darkened text-white":
                status === "default"
        }
    );

type TAddCumulativeMetricsButton = {
    option: {
        value: number | "*";
        status: AddCheckedMetricButtonStatus;
    };
};

const AddCumulativeMetricsButton = ({ option }: TAddCumulativeMetricsButton) => {
    const classes = getAddCumulativeMetricsButtonClasses(option.status);

    const [, dispatch] = useMetricsStore();
    const { selected } = useAddMetricsDropdown();
    const { checked } = useCumulative();

    const select = (metrics: Metrics.MetricInstance[]) =>
        dispatch({
            type: "SELECT",
            payload: metrics
        });
    const unselect = (metrics: Metrics.MetricInstance[]) =>
        dispatch({
            type: "UNSELECT",
            payload: metrics
        });

    const onAsteriskClick = () => {
        if (selected) {
            if (option.status === "inverted") {
                return select(
                    checked.flatMap(metric =>
                        selected.options.map(val => {
                            return Metrics.createMetric(metric.value, val);
                        })
                    )
                );
            }
            return unselect(
                checked.flatMap(metric =>
                    selected.options.map(val => {
                        return Metrics.createMetric(metric.value, val);
                    })
                )
            );
        }
    };

    const onNumberClick = () => {
        if (option.status === "inverted") {
            return select(
                checked.map(metric =>
                    Metrics.createMetric(metric.value, option.value as number)
                )
            );
        }
        return unselect(
            checked.map(metric =>
                Metrics.createMetric(metric.value, option.value as number)
            )
        );
    };

    const onClick = () =>
        option.value === "*" ? onAsteriskClick() : onNumberClick();

    const Asterisk = <Views.Icon name="asterisk" size="big" />;

    return (
        <div onClick={onClick} className={classes}>
            {option.value === "*" ? Asterisk : option.value}
        </div>
    );
};

type ViewLifetimeMetricButtonProps = {
    className?: string;
    onClick?: typeof unit;
    children: React.ReactNode;
    inverted?: boolean;
};

export const ViewLifetimeMetricButton = ({
    className = "",
    onClick,
    children,
    inverted = false
}: ViewLifetimeMetricButtonProps) => {
    const bgColor = {
        inverted: "bg-white hover:bg-c-accent1-lightened",
        default: "bg-c-accent1 hover:bg-c-accent1-darkened"
    };

    const textColor = {
        inverted: "text-c-accent1",
        default: "text-white"
    };

    const type = inverted ? "inverted" : "default";

    const classes = createClass(
        className,
        "center-v cursor-pointer py-2 px-4 font-bold border border-c-accent1 rounded",
        bgColor[type],
        textColor[type]
    );

    return (
        <div {...(onClick && { onClick })} className={classes}>
            {children}
        </div>
    );
};

type CumulativeMetricButtonProps = {
    metric: {
        value: Metrics.Cumulative;
        label: string;
        selected: boolean;
        checked: boolean;
    };
};

const CumulativeMetricButton = ({ metric }: CumulativeMetricButtonProps) => {
    const [metrics, dispatch] = useMetricsStore();
    const { toggleCheck } = useCumulative();

    const onClick = () => {
        toggleCheck(metric.value);
    };

    const iconName = metric.checked ? "check-square" : "square";

    const onRemove = () =>
        dispatch({
            type: "UNSELECT",
            payload: metrics.filter(
                item => item.value === metric.value && !!item.day_of_activity
            )
        });

    const classes = createClass(
        "mr-px w-full h-full",
        metric.selected ? "rounded-r-none" : "rounded"
    );

    return (
        <div className="center-v mr-2 mb-2 h-8">
            <ViewCumulativeMetricButton
                onClick={onClick}
                inverted={!metric.selected}
                className={classes}
            >
                <Views.Icon className="mr-2" name={iconName} size="normal" />
                {`DX ${metric.label}`}
            </ViewCumulativeMetricButton>
            {metric.selected && (
                <ViewCumulativeMetricButton
                    onClick={onRemove}
                    inverted={!metric.selected}
                    className="override:p-2 rounded-l-none h-full"
                >
                    <Views.Icon name="times" weight="solid" />
                </ViewCumulativeMetricButton>
            )}
        </div>
    );
};

type ViewCumulativeMetricButtonProps = {
    className?: string;
    onClick?: typeof unit;
    children: React.ReactNode;
    inverted?: boolean;
};

const ViewCumulativeMetricButton = ({
    className = "",
    onClick,
    children,
    inverted = false
}: ViewCumulativeMetricButtonProps) => {
    const bgColor = {
        inverted: "bg-white hover:bg-purple-lightened",
        default: "bg-purple hover:bg-purple-darkened"
    };

    const textColor = {
        inverted: "text-purple",
        default: "text-white"
    };

    const type = inverted ? "inverted" : "default";

    const classes = createClass(
        className,
        "center-v cursor-pointer py-2 px-4 font-bold border border-purple rounded",
        bgColor[type],
        textColor[type]
    );

    return (
        <div {...(onClick && { onClick })} className={classes}>
            {children}
        </div>
    );
};

type SelectedMetricButtonProps = {
    metric: Metrics.MetricInstance;
    provided: DraggableProvided;
    onRemove: typeof unit;
};

const SelectedMetricButton = ({
    metric,
    provided,
    onRemove
}: SelectedMetricButtonProps) => {
    const isCumulative = !!metric.day_of_activity;
    const content = Metrics.getLabel(metric);

    return (
        <div
            className="center-v w-full mb-2 h-8"
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
        >
            {isCumulative ? (
                <div className="cursor-grab mr-1px w-full h-full rounded-r-none center-v">
                    <ViewCumulativeMetricButton className="mr-px w-full">
                        <Views.Icon
                            className="mr-2"
                            name="grip-vertical"
                            weight="solid"
                        />
                        {content}
                    </ViewCumulativeMetricButton>
                    <ViewCumulativeMetricButton
                        onClick={onRemove}
                        className="rounded-l-none center w-7"
                    >
                        <Views.Icon name="times" weight="solid" />
                    </ViewCumulativeMetricButton>
                </div>
            ) : (
                <div className="cursor-grab mr-1px w-full h-full rounded-r-none center-v">
                    <ViewLifetimeMetricButton className="mr-px w-full">
                        <Views.Icon
                            className="mr-2"
                            name="grip-vertical"
                            weight="solid"
                        />
                        {content}
                    </ViewLifetimeMetricButton>
                    <ViewLifetimeMetricButton
                        onClick={onRemove}
                        className="rounded-l-none center w-7"
                    >
                        <Views.Icon name="times" weight="solid" />
                    </ViewLifetimeMetricButton>
                </div>
            )}
        </div>
    );
};
