import { createClass } from "@/reexports";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import { Icon } from "@/views";
import React, { useCallback, useMemo, useState } from "react";

type Props<T> = {
	onSave: (value: T) => Promise<any>;
	onDismiss?: (value: T) => void;
	value: T;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useInputWithConfirmation = <T, uknown>(props: Props<T>) => {
	const { onDismiss, onSave, value } = props;
	const [dirtyValue, setDirtyValue] = useState<T | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const edit = useCallback(() => {
		if (dirtyValue) return;
		setDirtyValue(value);
	}, [dirtyValue, value]);

	const editing = useMemo(() => !!dirtyValue, [dirtyValue]);

	const save = useCallback(() => {
		setLoading(true);
		onSave(dirtyValue || value).then(() => {
			setLoading(false);
			setDirtyValue(undefined);
		});
	}, [dirtyValue, onSave, value]);

	const dismiss = useCallback(() => {
		if (onDismiss) {
			onDismiss(dirtyValue || value);
		}
		setDirtyValue(undefined);
	}, [dirtyValue, onDismiss, value]);

	const onChange = (val: T) => {
		setDirtyValue(val);
	};

	return {
		value: dirtyValue || value,
		dirtyValue,
		onChange,
		edit,
		editing,
		save,
		dismiss,
		loading,
	};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InputWithConfirmation = <T, uknown>(props: {
	className?: string;
	children: JSX.Element;
	dirtyValue: T | undefined;
	loading: boolean;
	save: () => void;
	dismiss: () => void;
}) => {
	const { className, children, dismiss, loading, save, dirtyValue } = props;

	return (
		<div className={createClass("relative", className)}>
			{children}
			{dirtyValue && (
				<div className="absolute center-v gap-2 top-0 right-0 transform translate-x-10 translate-y-1/2">
					{loading ? (
						<LoadingIcon />
					) : (
						<Icon
							className="cursor-pointer text-success"
							name="check"
							onClick={(e) => {
								e.stopPropagation();
								save();
							}}
						/>
					)}
					<Icon
						className="cursor-pointer text-danger"
						name="times"
						onClick={dismiss}
					/>
				</div>
			)}
		</div>
	);
};
