import * as Auth from "@/services/auth";
import { unit } from "@/functions/unit";
import { useEffect, useState } from "react";
import { createStore } from "reusable";
import { ofType, unionize, UnionOf } from "unionize";

export const useSession = createStore(() => {
	const [session, setSession] = useState<TModel>({ tag: "NotLoggedIn" });

	useEffect(() => {
		Auth.initialCheck()
			.then((res: Auth.Session) => {
				setSession(Model.LoggedIn(res));
			})
			.catch(unit);
	}, []);

	const signIn = () =>
		Auth.signIn()
			.then(session => setSession(Model.LoggedIn(session)))
			.catch(e => console.error("Failed to sign in: ", e));

	const signOut = () =>
		Auth.signOut()
			.then(session => setSession(Model.NotLoggedIn()))
			.catch(e => console.error("Failed to sign out: ", e));

	return { session, signIn, signOut };
});

const Model = unionize({
	NotLoggedIn: {},
	LoggedIn: ofType<Session>()
});

export const as = Model.as;

export const isLoggedIn = Model.match({
	NotLoggedIn: () => false,
	LoggedIn: () => true
});

export type TModel = UnionOf<typeof Model>;
export type Session = Auth.Session;
