import { Feed } from "@/services/graphApi/graphql-zeus";
import { useEffect } from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const FeedTypeMatch = () =>
	createUIKitMatcher(
		({ field }) => field.type === "enum" && field.enumType === "Feed"
	);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FeedTypeField = <R, uknown>({
	field,
	path,
	form,
	data,
}: UIFieldProps<R>) => {
	const fieldPath = path.concat(field.name).join(".");

	useEffect(() => {
		form.setValue(fieldPath, data || Feed.APPLE);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return null;
};
