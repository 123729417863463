import { camelCaseToWords } from "@/functions/camelCaseToWords";
import { Icon } from "@/views";
import React from "react";
import { createUIKitMatcher, UIFieldProps } from ".";

export const UpdateManyInputMatch = () =>
	createUIKitMatcher(({ field }) => field.type.endsWith("UpdateManyInput"));

export const UpdateManyInputField = <
	R extends { create: []; delete: []; update: [] },
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	uknown
>({
	field,
	path,
	form,
	data,
	UIField,
	uiKit,
}: UIFieldProps<R>) => {
	const createPath = path.concat(field.name, "create").join(".");
	const deletePath = path.concat(field.name, "delete").join(".");
	const updatePath = path.concat(field.name, "update").join(".");

	return (
		<div>
			<div>
				<div className="mr-2">{camelCaseToWords(field.name)}</div>
				{data &&
					data.update.map((updateField: any, index: number) => {
						return (
							<div
								key={`${updateField.id}${index}`}
								className="flex mr-4"
							>
								<div className="mr-4">
									{field.fields &&
										field.fields[2]?.fields?.map(
											(item, i) => {
												return (
													<UIField
														key={`${updateField.id}${index}${item.name}`}
														field={item}
														form={form}
														data={
															updateField[
																item.name
															]
														}
														path={path.concat(
															`${field.name}.update[${index}]`
														)}
														uiKit={uiKit}
														UIField={UIField}
													/>
												);
											}
										)}
								</div>
								<div>
									<button
										onClick={(e) => {
											e.preventDefault();
											form.pushFieldValue(deletePath, {
												id: updateField.id,
											});
											form.removeFieldValue(
												updatePath,
												index
											);
										}}
									>
										Remove
									</button>
								</div>
							</div>
						);
					})}
				{data &&
					data.create.map((createField: any, index: number) => {
						return (
							<div
								key={`${createField.id}${index}`}
								className="flex mr-4"
							>
								<div className="mr-4">
									{field.fields &&
										field.fields[0]?.fields?.map(
											(item, i) => {
												return (
													<UIField
														key={`${createField.id}${index}${item.name}`}
														field={item}
														form={form}
														data={
															createField[
																item.name
															]
														}
														path={path.concat(
															`${field.name}.create[${index}]`
														)}
														uiKit={uiKit}
														UIField={UIField}
													/>
												);
											}
										)}
								</div>
								<div>
									<button
										onClick={(e) => {
											e.preventDefault();
											form.removeFieldValue(
												createPath,
												index
											);
										}}
									>
										Remove
									</button>
								</div>
							</div>
						);
					})}
			</div>
			<Icon
				name="plus"
				onClick={(e) => {
					e.preventDefault();
					form.pushFieldValue(createPath, {});
				}}
			/>
		</div>
	);
};
