import { useOrganizationQuery } from "@/modules/Organizations/logic";
import { LoadingIcon } from "@/ui/views/LoadingIcon";
import { Icon, IconProps } from "@/views";
import React from "react";

type Props = {
	id: string;
	size?: IconProps["size"];
};

export const WorkspaceLogo = ({ id, size }: Props) => {
	const organization = useOrganizationQuery(id);
	const logoPath = organization.data?.organization?.logoPath;

	if (organization.loading) {
		return <LoadingIcon className="text-blue-300" size="big" />;
	}

	if (logoPath) {
		return (
			<div
				className="w-20 h-20 inline-block bg-contain bg-no-repeat"
				style={{ backgroundImage: `url(${logoPath})` }}
			/>
		);
	}

	return <Icon name="question-circle" className="text-danger" size={size} />;
};
