import * as Navigation from "@/components/Page/Navigation";
import * as Session from "@/components/Session";
import { match } from "@/data-types/generic";
import { or } from "@/data-types/logic";
import { Route, routeEquals, useRouter } from "@/modules/Router";
import { Viewer } from "@/modules/Viewer/components/Viewer";
import { Workspaces } from "@/modules/workspaces/components";
import { WorkspaceDetail } from "@/modules/workspaces/components/Detail";
import { Header, Sidebar } from "@/views";
import { NavigationLink } from "@/views/NavigationLink";
import React, { useState } from "react";
import * as BQChecks from "./BQChecks";
import * as ConsistencyChecks from "./ConsistencyChecks";
import { Accesses } from "./Etl/Accesses";
import * as Etl from "./Etl/Configs";
import * as Instances from "./Etl/Instances";
import * as Home from "./Home";
import * as Login from "./Login";
import * as NotFound from "./NotFound";
import * as Settings from "./Settings";
export { Home, Login, Settings, NotFound };

export const View = () => {
	const { route } = useRouter();
	const { session, signIn } = Session.useSession();
	const [sidebarOpened, setSidebarOpened] = useState(true);
	const toggleSidebarOpened = () => setSidebarOpened(!sidebarOpened);

	if (!Session.isLoggedIn(session)) {
		return <Login.View signIn={signIn} />;
	}
	return (
		<div className="flex w-screen h-screen overflow-hidden bg-gray-100">
			<Sidebar.View
				opened={sidebarOpened}
				onIconClick={toggleSidebarOpened}
			>
				{/* {match(route.route, (route) => {
						switch (route) {
							case "home":
								return <Home.Sidebar.View />;
							default:
								return undefined;
						}
					})} */}
			</Sidebar.View>
			<div className="w-full h-full overflow-overlay">
				<Header navigation={<Navigation.View />} user={<Viewer />} />
				{(route.route === "home" || route.route.startsWith("etl")) && (
					<div className="flex px-4 py-2">
						<NavigationLink
							active={route.route.startsWith("etl-accesses")}
							to={{ route: "etl-accesses" }}
						>
							<span>Accesses</span>
						</NavigationLink>
						<NavigationLink
							active={
								route.route === "etl-configs" ||
								route.route === "home"
							}
							to={{ route: "etl-configs" }}
						>
							<span>Configs</span>
						</NavigationLink>
						<NavigationLink
							active={route.route === "etl-instances"}
							to={{ route: "etl-instances" }}
						>
							<span>Instances</span>
						</NavigationLink>
					</div>
				)}
				{match(
					route.route,
					[
						[routeEquals("login"), () => <Etl.View />],
						[routeEquals("home"), () => <Etl.View />],
						[routeEquals("settings"), () => <Settings.View />],
						[
							or(
								routeEquals("etl-accesses"),
								routeEquals("etl-accesses-detail")
							),
							() => <Accesses />,
						],
						[
							or(
								routeEquals("etl-configs"),
								routeEquals("etl-configs-detail")
							),
							() => <Etl.View />,
						],
						[
							or(
								routeEquals("etl-instances"),
								routeEquals("etl-instances-detail")
							),
							() => <Instances.View />,
						],
						[routeEquals("workspaces"), () => <Workspaces />],
						[
							routeEquals("workspaces-detail"),
							() => (
								<Route path="workspaces/:id">
									{({ id }) => <WorkspaceDetail id={id} />}
								</Route>
							),
						],
						[routeEquals("checks-bq"), () => <BQChecks.View />],
						[
							routeEquals("checks-consistency-daily"),
							() => <ConsistencyChecks.View tableType="DAILY" />,
						],
						[
							routeEquals("checks-consistency-campaign"),
							() => (
								<ConsistencyChecks.View tableType="CAMPAIGN" />
							),
						],
					],
					() => (
						<NotFound.View />
					)
				)}
			</div>
		</div>
	);
};
