import {
	gql,
	MutationHookOptions,
	OperationVariables,
	QueryBaseOptions,
	QueryHookOptions,
	useApolloClient,
	useLazyQuery,
	useMutation,
	useQuery,
} from "@apollo/client";
import {} from "@apollo/client/";
import { notifyError } from "../notification";
import {
	AllTypesProps,
	MapType,
	Mutation,
	Query,
	ValueTypes,
	Zeus,
} from "./graphql-zeus";

export type Field = {
	fields?: Field[];
	array: boolean;
	arrayRequired: boolean;
	enumType?: string;
	name: string;
	required: boolean;
	type: string;
};

// const getInputs = (fields: Record<string, any>) => (input: string, filterFn?: (field: Field) => boolean ): Field[] => {
// 	const result: unknown[] = [];
// 	const getAllFields = (obj: Record<string, any>, name: string = ""): any => {
// 		if (filterFn && !filterFn({ name, ...obj } as Field)) {
// 			return;
// 		}
// 		if (typeof obj === "object" && obj.type && isScalar(obj.type)) {
// 			result.push({ name, ...obj });
// 			return;
// 		}

// 		if (
// 			typeof obj === "object" &&
// 			obj.type &&
// 			fields[obj.type] === "enum"
// 		) {
// 			result.push({
// 				name,
// 				...obj,
// 				type: "enum",
// 				enumType: obj.type,
// 			});
// 			return;
// 		}

// 		if (typeof obj === "object" && obj.type) {
// 			if (obj.array) {
// 				result.push({ name, ...obj });
// 			}
// 			Object.keys(fields[obj.type]).forEach((key) => {
// 				getAllFields(fields[obj.type][key], `${name ? `${name}.` : name}${key}`);
// 			});
// 		}

// 		if (typeof obj === "object" && !obj.type) {
// 			Object.keys(obj).forEach((key) => {
// 				getAllFields(obj[key], `${name ? `${name}.` : name}${key}`);
// 			});
// 			return;
// 		}

// 		return;
// 	};

// 	getAllFields(fields[input]);
// 	return result as Field[];
// };
const getInputs = (fields: Record<string, any>) => (input: string): Field[] => {
	const getAllFields = (obj: Record<string, any>, name: string = ""): any => {
		if (typeof obj === "object" && obj.type && isScalar(obj.type)) {
			return { name, ...obj };
		}

		if (
			typeof obj === "object" &&
			obj.type &&
			fields[obj.type] === "enum"
		) {
			return {
				name,
				...obj,
				type: "enum",
				enumType: obj.type,
			};
		}

		if (typeof obj === "object" && obj.type) {
			if (obj.array) {
				return [
					{
						name,
						fields: Object.keys(fields[obj.type])
							.map((key) => {
								return getAllFields(fields[obj.type][key], key);
							})
							.flat(),
						...obj,
					},
				];
			}

			return [
				{
					name,
					fields: Object.keys(fields[obj.type])
						.map((key) => {
							return getAllFields(fields[obj.type][key], key);
						})
						.flat(),
					...obj,
				},
			];
		}

		if (typeof obj === "object" && !obj.type) {
			return Object.keys(obj)
				.map((key) => {
					return getAllFields(obj[key], key);
				})
				.flat();
		}

		return [];
	};

	return getAllFields(fields[input]);
};
const isScalar = (type: string) => {
	if (type === "String") return true;
	if (type === "Int") return true;
	if (type === "Float") return true;
	if (type === "Boolean") return true;
	if (type === "ID") return true;
	if (type === "enum") return true;
	return false;
};

export const getInputFields = getInputs(AllTypesProps);

export const GRAPH_ENDPOINT = "/graph-api";

export function useTypedQuery<Q extends ValueTypes["Query"]>(
	query: Q,
	options?: QueryHookOptions<MapType<Query, Q>, Record<string, any>>
) {
	return useQuery<MapType<Query, Q>>(gql(Zeus.query(query)), {
		onError: (e) => {
			notifyError(e.message);
		},
		...options,
	});
}

export function useTypedLazyQuery<Q extends ValueTypes["Query"]>(
	query: Q,
	options?: QueryHookOptions<MapType<Query, Q>, Record<string, any>>
) {
	return useLazyQuery<MapType<Query, Q>>(gql(Zeus.query(query)), {
		onError: (e) => {
			notifyError(e.message);
		},
		...options,
	});
}
export function useTypedFetchQuery<Q extends ValueTypes["Query"]>(
	query: Q,
	options?: Omit<QueryBaseOptions<OperationVariables>, "query">
) {
	const client = useApolloClient();
	return (_options?: Omit<QueryBaseOptions<OperationVariables>, "query">) =>
		client.query<MapType<Query, Q>>({
			query: gql(Zeus.query(query)),
			...options,
			..._options,
		});
}

export function useTypedMutation<Q extends ValueTypes["Mutation"]>(
	mutation: Q,
	options?: MutationHookOptions<MapType<Mutation, Q>, Record<string, any>>
) {
	return useMutation<MapType<Mutation, Q>>(gql(Zeus.mutation(mutation)), {
		onError: (e) => {
			notifyError(e.message);
		},
		...options,
	});
}

// function useTypedSubscription<Q extends ValueTypes["Subscription"]>(
// 	subscription: Q,
// 	options?: SubscriptionHookOptions<
// 		MapType<Subscription, Q>,
// 		Record<string, any>
// 	>
// ) {
// 	return useSubscription<MapType<Subscription, Q>>(
// 		gql(Zeus.subscription(subscription)),
// 		options
// 	);
// }
