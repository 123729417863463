import { createGuid } from "@/functions/createGuid";
import { useTypedFetchQuery } from "@/services/graphApi";
import { $ } from "@/services/graphApi/graphql-zeus";
import {
	notifyError,
	notifyProgress,
	notifySuccess,
} from "@/services/notification";
import { Icon, Modal } from "@/views";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

type UseUploadProps = {
	useToast?: boolean;
	onUploaded?: <T = any>(result: T, isLatest: boolean) => void;
};

export const useUpload = (props: UseUploadProps) => {
	const latestUploadRef = useRef(0);
	const { useToast = true, onUploaded } = props;
	const [file, setFile] = useState<File>();
	const _getUploadUrl = useTypedFetchQuery({
		getUploadUrl: [
			{
				fileContentType: $`type`,
				fileName: $`name`,
			},
			{ fileName: true, uploadURL: true },
		],
	});
	const [uploadUrlState, setUploadUrlState] = useState<{
		data?: { getUploadUrl: { fileName: string; uploadURL: string } };
		error?: any;
		loading: boolean;
	}>({ loading: false });

	const [uploading, setUploading] = useState<boolean>(false);

	const getUploadUrl = useCallback(() => {
		if (!file || !file?.type || !file.name) {
			return;
		}
		setUploadUrlState({ loading: true });
		return _getUploadUrl({
			variables: {
				type: file.type,
				name: file.name,
			},
		})
			.then((res) => {
				setUploadUrlState({
					data: res.data,
					loading: false,
				});
			})
			.catch((e) => {
				setUploadUrlState({
					error: e,
					loading: false,
				});
			});
	}, [_getUploadUrl, file]);

	const upload = useCallback(async () => {
		if (!file || !file?.type || !file.name) {
			return;
		}
		if (!uploadUrlState.data) return;

		const { fileName, uploadURL } = uploadUrlState.data.getUploadUrl;

		let toastId: React.ReactText;
		if (useToast) {
			toastId = notifyProgress(`Uploading ${file.name}`);
		}

		setUploadUrlState({ loading: false });
		setUploading(true);

		const promiseId = (latestUploadRef.current + 1) % 100;
		latestUploadRef.current = promiseId;

		axios
			.put(uploadURL, file, {
				headers: {
					"Content-Type": file.type,
				},
				onUploadProgress: (e) => {
					console.log({ toastId });

					if (toastId) {
						toast.update(toastId, {
							progress: e.loaded / e.total,
						});
					}
				},
			})
			.then(() => {
				if (useToast) {
					toast.dismiss(toastId);
					notifySuccess(`${file.name} uploaded`);
				}
				if (onUploaded) {
					onUploaded(fileName, promiseId === latestUploadRef.current);
				}
				setUploading(false);
			})
			.catch((e) => {
				if (useToast) {
					notifyError(`File failed to upload: ${e}`);
				}
				setUploading(false);
			});
	}, [file, onUploaded, uploadUrlState.data, useToast]);

	useEffect(() => {
		if (uploadUrlState.data) {
			upload();
			setFile(undefined);
		}
	}, [setFile, upload, uploadUrlState.data]);

	return {
		file,
		setFile,
		upload,
		uploading,
		getUploadUrl,
		uploadUrlState,
	};
};

type Props = {
	onClose: () => void;
	opened: boolean;
} & ReturnType<typeof useUpload>;

export const Upload = (props: Props) => {
	const { onClose, opened } = props;
	const { file, setFile, upload, getUploadUrl, uploadUrlState } = props;
	const [inputId] = useState(createGuid());

	useEffect(() => {
		if (uploadUrlState.data) {
			onClose();
		}
	}, [onClose, setFile, upload, uploadUrlState.data]);

	return (
		<Modal isOpened={opened} onClose={onClose} className="w-144 center">
			<div className="w-full h-full relative p-5">
				<Icon
					name="times"
					onClick={onClose}
					weight="solid"
					className="absolute top-0 right-0 mr-2 mt-2 cursor-pointer"
				/>
				<form
					className="center flex-col gap-4"
					onSubmit={async (e) => {
						e.preventDefault();
						getUploadUrl();
					}}
				>
					<label
						htmlFor={`upload-${inputId}`}
						className="cursor-pointer group"
					>
						<div className="center-v flex-col gap-2">
							<div className="bg-primary group-hover:bg-primary-darkened rounded-full p-8">
								<Icon
									name="upload"
									weight="solid"
									size="huge"
									className="text-white"
								/>
							</div>
							<div className="group-hover:font-semibold">
								{file ? file.name : "Choose a file..."}
							</div>
						</div>
					</label>
					<input
						id={`upload-${inputId}`}
						className="hidden"
						type="file"
						onChange={(e) => {
							if (e.target?.files && e.target.files[0]) {
								setFile(e.target.files[0]);
							}
						}}
					/>
					{file ? (
						<button type="submit" className="btn-primary">
							Upload{" "}
							{uploadUrlState.loading ? (
								<Icon name="spinner" className="spin" />
							) : null}
						</button>
					) : (
						<button
							type="submit"
							className="btn bg-gray-300 cursor-not-allowed"
							disabled
						>
							Upload
						</button>
					)}
				</form>
			</div>
		</Modal>
	);
};
