import {
	IntegrationFeedConfigSelector,
	useCreateIntegrationFeedConfig,
	useDeleteIntegrationFeedConfig,
} from "@/modules/Etl/IntegrationFeedConfigs/logic";
import { useRunEtl } from "@/modules/Etl/IntegrationFeedInstances/logic";
import {
	FeedIcon,
	feedTypeToBrand,
} from "@/modules/Etl/IntegrationFeedParams/ui/FeedIcon";
import { Link } from "@/modules/Router";
import { useOpenable } from "@/modules/useOpenable";
import { createClass } from "@/reexports";
import { useTypedFetchQuery } from "@/services/graphApi";
import { IntegrationFeedConfig } from "@/services/graphApi/graphql-zeus";
import { Dropdown, Icon, Tile } from "@/views";
import React from "react";
import { useConfigsDetail } from "./Detail/Existing";

type Props = {
	item: IntegrationFeedConfig;
	refetch: () => void;
};

const SIZE = "w-80 h-40";
export const ConfigTile = ({ item, refetch }: Props) => {
	const { setRemove } = useConfigsDetail();
	const deleted = useOpenable(false);
	const menu = useOpenable(false);

	const [runEtl] = useRunEtl();
	const [copy, copyState] = useCreateIntegrationFeedConfig(
		item.params.feedType
	);
	const [remove, removeState] = useDeleteIntegrationFeedConfig();
	const fetch = useTypedFetchQuery({
		integrationFeedConfig: [
			{ where: { id: item.id } },
			IntegrationFeedConfigSelector(item.params.feedType),
		],
	});

	if (removeState.loading) {
		const deleteClasses = createClass(SIZE, "center p-6 transition-03", {
			"opacity-0": deleted.opened,
		});
		return (
			<Tile className={deleteClasses}>
				<Icon name="spinner" className="spin" size="big" />
			</Tile>
		);
	}

	if (removeState.error) {
		return (
			<Tile className={createClass(SIZE, "center p-6")}>
				<Icon name="spinner" className="spin" size="big" />
			</Tile>
		);
	}

	const classes = createClass(
		SIZE,
		"flex flex-col p-6 justify-between transition-03",
		{ "opacity-0": deleted.opened }
	);

	return (
		<Tile className={classes}>
			<>
				<div className="flex flex-grow items-center mb-4">
					<div className="mr-6">
						<FeedIcon
							type={feedTypeToBrand(item.params.feedType)}
						/>
					</div>
					<div className="">
						<div className="font-bold">
							{item.params.descriptiveName}
						</div>
						{/* <div className="text-sm text-primary underline">
						Last run
					</div> */}
					</div>
				</div>
				<div className="flex items-center justify-around">
					<Link
						to={{
							route: "etl-configs-detail",
							params: {
								id: item.id,
								feedType: item.params.feedType,
							},
						}}
					>
						<Icon
							className="hover:text-blue-300 transition duration-100"
							name="edit"
							weight="solid"
							size="big"
							onClick={() => {
								setRemove(() => () =>
									remove({
										variables: {
											where: {
												id: item.id,
											},
										},
										update: () => {
											deleted.open();
											refetch();
										},
									})
								);
							}}
						/>
					</Link>
					{copyState.loading ? (
						<Icon
							className="spin"
							name="spinner"
							weight="solid"
							size="big"
						/>
					) : (
						<Icon
							className="hover:text-blue-300 transition duration-100"
							name="copy"
							weight="solid"
							size="big"
							onClick={(e) => {
								e.stopPropagation();
								fetch().then((res) => {
									const cfg = res.data?.integrationFeedConfig;
									const data = JSON.parse(
										JSON.stringify(
											cfg,
											configToConfigCreateInput
										)
									);

									copy({
										variables: {
											data,
										},
										update: refetch,
									});
								});
							}}
						/>
					)}
					<Dropdown.View
						className="hover:text-blue-300 transition duration-100"
						onClickOutside={menu.close}
						opened={menu.opened}
						label={
							<Icon
								onClick={(e) => {
									e.stopPropagation();
									menu.toggle();
								}}
								name="ellipsis-h"
								weight="solid"
								size="big"
							/>
						}
					>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									fetch().then((res) => {
										const transformedItem = JSON.parse(
											JSON.stringify(
												res.data?.integrationFeedConfig,
												configToInstance
											)
										);

										runEtl({
											variables: {
												data: {
													organizationAttributes:
														transformedItem.organizationAttributes,
													params:
														transformedItem.params,
													config: {
														id: transformedItem.id,
													},
												},
											},
										});
									});
									menu.close();
								}}
							>
								<div>Run instance</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown.View>
				</div>
			</>
		</Tile>
	);
};

const configToInstance = (key: string, value: any) => {
	if (key === "") {
		return value;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	if (key === "bqDestinations" || key === "s3Destinations") {
		return value
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
	}

	return value;
};

const configToConfigCreateInput = (key: string, value: any) => {
	if (key === "") {
		const { id, updatedAt, createdAt, ...rest } = value;
		return rest;
	}
	if (value === null) {
		return;
	}
	if (key === "__typename") {
		return;
	}
	if (key === "descriptiveName") {
		return `${value} Copy`;
	}
	if (key === "organization") {
		return { id: value.id };
	}
	if (key === "dateInput") {
		const { id, ...rest } = value;
		return rest;
	}
	if (key === "organizationAttributes") {
		return value.map((item: any) => {
			const { id, ...rest } = item;
			return rest;
		});
	}
	if (key === "bqDestinations" || key === "s3Destinations") {
		return value
			.filter((item: any) => item.enabled)
			.map((item: any) => {
				const { id, ...rest } = item;
				return rest;
			});
	}

	return value;
};
